import React, { useContext, useEffect }  from "react"
import { graphql } from 'gatsby'
import SbEditable from 'storyblok-react'

import Seo from "../components/services/seo"
import DynamicComponent from "../components/services/dynamicComponent"
import useStoryblok from "../lib/storyblok"
import { CurrentPageContext } from "../context"

const JournalPage = ({ data, location }) => {
  let story = data.storyblokEntry
  story = useStoryblok(story, location)

  const { currentPage, setCurrentPage} = useContext(CurrentPageContext);
  useEffect(() => {
    setCurrentPage([{
      slug: '',
      name: story.name
    }])
  }, [setCurrentPage, story.name])

  const components = story.content.body.map(blok => {
    return (<DynamicComponent blok={blok} data={data} key={blok._uid} />)
  })
  
  const schema = story.content.schema_markup;

  return (
    <SbEditable content={story.content}>
      <Seo title={story.content.title_meta} description={story.content.description_meta} schemaMarkup={schema} url={location.href} />
      { components }
    </SbEditable>
)}

export default JournalPage

export const query = graphql`
  query JournalQuery {
    storyblokEntry(full_slug: {eq: "home-decor-blog"}) {
      content
      name
    }   
    journalitems: allStoryblokEntry(
      sort: {fields: created_at}
      filter: {field_component: {eq: "journalpost"}}
    ) {
      edges {
        node {
          id
          uuid
          name
          slug
          full_slug
          content
          created_at
        }
      }
    }
    tags: allStoryblokEntry(
      sort: {fields: created_at}
      filter: {field_component: {eq: "tag"}}
    ) {
      edges {
        node {
          id
          uuid
          name
          slug
          full_slug
          content
          created_at
        }
      }
    }
    images: allImageSharp {
       edges {
         node {
           gatsbyImageData(
             width: 200
             placeholder: BLURRED
             formats: [AUTO, WEBP, AVIF]
             quality:50
           )

         }
       }
     }
  }`
 
